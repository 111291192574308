<template>
  <v-sheet color="transparent" :width="width" :min-width="minWidth" style="position: relative">
    <div  v-if="label" class="d-flex justify-space-between align-center mb-2">
      <h5 :style="`color : ${labelColor || '#4F5A6E' }; font-size: 13px; font-weight: 500 `" >  {{  label  }} </h5>
      <ws-tooltip :text="tooltip" v-if="tooltip">
        <v-icon :color="wsDARKLIGHT">mdi-information-outline</v-icon>
      </ws-tooltip>
    </div>

    <v-text-field
        @click.prevent="$emit('click' , $event)"
        v-if="!area"
        v-model="text"
        :background-color="backgroundColor"
        @keypress="handleKeyPress( $event,text )"
        @focusin="focused = true; $emit('focus')"
        @blur="focused = false ; $emit('change',text);  $emit('blur')"
        :class="{
          activeText : focused && !error && !defaultStyle,
          notActive : !focused && !error && !defaultStyle,
          errorStyle : error && !defaultStyle,
          textStyle : !defaultStyle,
          avalon : avalonStyle
        }"
        @change="focused = false"
        class="mt-0 pa-0"
        :placeholder="placeholder"
        :color="!defaultStyle ? wsACCENT : color"
        :type="type"
        :dense="dense"
        :outlined="outlined && !flat"
        :error="error"
        :height="heightCalculated"
        :counter="counter"
        :clearable="clearable"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :prepend-inner-icon="prependInnerIcon"
        :readonly="readonly"
        :append-icon="appendIcon"
        :flat="flat"
        :solo="solo"
        :required="required"
        :disabled="disabled"
    >

      <template v-if="!prependInnerIcon" #prepend-inner>
        <slot name="prepend-inner">
          <v-icon v-if="icon" :color="focused ? wsACCENT : wsBACKGROUND">{{ icon }}</v-icon>
        </slot>
      </template>

      <template #append>
        <slot  name="append"></slot>

        <v-btn icon @click="toggleDisplayPassword" style="margin-top: -6px" v-if="password || newPassword">
          <v-icon>{{ displayPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
        </v-btn>
      </template>



    </v-text-field>
    <v-textarea
          v-else
          @click.prevent="$emit('click' , $event)"
          v-model="text"
          @input="$emit('text-input' , $event)"
          :background-color="backgroundColor"
          @keypress="handleKeyPress( $event,text )"
          @focusin="focused = true"
          @blur="focused = false ; $emit('change',text)"
          :class="{
          activeText :  focused && !error && !defaultStyle,
          notActive : !focused && !error && !defaultStyle,
          errorStyle : error && !defaultStyle,
          textStyle : !defaultStyle,
          avalon : avalonStyle
        }"
          class="textStyle mt-0"
          :placeholder="placeholder"
          :color="!defaultStyle ? wsACCENT : color"
          :type="type"
          :dense="dense"
          :outlined="outlined && !flat"
          :hide-details="!counter"
          :error="error"
          :height="height === '40' ? null : height"
          :counter="counter"
          :clearable="clearable"
          auto-grow
          :rows="rows"

          :error-messages="errorMessages"
          :prepend-inner-icon="prependInnerIcon"
          :readonly="readonly"
          :append-icon="appendIcon"
          :flat="flat"
          :solo="solo"
          :required="required"
          :disabled="disabled"
          :maxlength="maxlength || null"
      >
        <template #prepend-inner>
          <v-sheet v-if="lined" style="margin-top: 7px">
            <v-sheet v-for="line in lines" :key="line">
              <h5 :style="`color : ${wsLIGHTACCENT}; margin-top: ${line > 1 ? '16px' : '0'}`" class="mr-2" >{{ line }} </h5>
            </v-sheet>
          </v-sheet>

        </template>
      </v-textarea>
    <h5 :style="`color : ${wsDARKLIGHT}; font-size : 13px`" class="text-right font-weight-regular mt-1" v-if="maxChars"> {{ text.length }} / {{ maxChars }}</h5>
    <v-divider v-if="avalonStyle"  :style="`border-color : ${wsDARKLIGHT}`" />
    <!-- Description-->
    <h5 v-if="description" class="font-weight-medium mt-2" :style="`color: ${wsDARKLIGHT}`">{{ $t(description) }}</h5>
    <v-sheet
        v-if="displayPasswordTooltip && false"
    >
      <h5 :style="`color : ${wsDARKLIGHT}`" class="mt-3">
        <v-icon :color="wsDARKLIGHT">mdi-circle-small</v-icon>
        {{ $t('homepage.register.regex.size') }}
      </h5>
      <h5 :style="`color : ${wsDARKLIGHT}`" class="mt-3">
        <v-icon :color="wsDARKLIGHT">mdi-circle-small</v-icon>
        {{ $t('homepage.register.regex.number') }}
      </h5>
      <h5 :style="`color : ${wsDARKLIGHT}`" class="mt-3">
        <v-icon :color="wsDARKLIGHT">mdi-circle-small</v-icon>
        {{ $t('homepage.register.regex.capital_letter') }}
      </h5>
      <h5 :style="`color : ${wsDARKLIGHT}`" class="mt-3">
        <v-icon :color="wsDARKLIGHT">mdi-circle-small</v-icon>
        {{ $t('homepage.register.regex.symbol') }}
      </h5>
    </v-sheet>

  </v-sheet>
</template>

<script>
export default {
  name: "wsTextField",
  props : {
    value : {},
    placeholder : {
      type : String,
    },
    label : {
      type : String,
    },
    tooltip : {
      type : String,
    },
    description : {
      type : String,
    },
    color : {
      type : String
    },
    backgroundColor : {
      type : String
    },
    labelColor : {
      type : String,
    },
    number : {
      type : Boolean,
      default : false
    },
    numberLength : {
      type : String,
      default : '10000000',
    },
    maxlength : {
      type : String,
    },
    error : {
      type : Boolean,
      default : false
    },
    area : {
      type : Boolean,
      default : false
    },
    counter : {
      type : String,
    },
    height : {
      type : String,
      default : '40'
    },
    clearable : {
      type : Boolean,
      default : false
    },
    icon   : {
      type : String
    },
    dense : {
      type : Boolean,
      default : true
    },
    hideDetails : {
      type : Boolean,
      default : true
    },
    errorMessages : {
      type : [Array, String],
      default : () => []
    },
    defaultStyle : {
      type : Boolean,
      default : false
    },
    hideNumberHandle : {
      type : Boolean,
      default : false
    },
    width : {
      type : String,
    },
    minWidth : {},
    solo : {
      type : Boolean,
      default : false
    },
    flat : {
      type : Boolean,
      default : false
    },
    outlined : {
      type : Boolean,
      default : true
    },
    prependInnerIcon : {
      type :String,
    },
    appendIcon : {
      type :String,
    },
    readonly : {
      type : Boolean,
      default : false
    },
    lined : {
      type : Boolean,
      default : false
    },
    required : {
      type : Boolean,
      default : false
    },
    password : {
      type : Boolean,
      default : false
    },
    newPassword : {
      type : Boolean,
      default : false
    },
    disabled : {
      type : Boolean,
      default : false
    },
    avalonStyle : {
      type : Boolean,
      default : false
    },
    maxChars : {
      type : Number
    },
    rows : {
      tye : String,
      default : 3
    }
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.value !== this.text ) {
        this.$emit('input',this.text)
      }
    },
  },
  data() {
    return {
      text : '',
      focused : false,
      displayPassword : false
    }
  },
  computed : {
    heightCalculated() {
      let height = parseInt(this.height)
      if ( this.SM ) {
        return  height + 6
      }
      return height
    },
    displayPasswordTooltip() {
      return this.focused && this.newPassword
    },
    lines() {
      if ( !this.text ) {
        return 1
      }
      let array = this.text.split('\n')
      return array.length
    },
    type() {
      if ( this.password || this.newPassword) {
        return this.displayPassword ? 'text' : 'password'
      }
      if ( this.number && !this.hideNumberHandle ) {
        return 'number'
      }
      return 'text'
    }
  },
  methods : {
    toggleDisplayPassword() {
      this.displayPassword = !this.displayPassword
    },
    handleKeyPress($event , element ) {
      if ( this.maxChars ) {
        if ( this.text.length >= this.maxChars ) {
          let input = (input) ? input : window.event;
          input.preventDefault();
        }
      }
      if ( this.number ) {
        this.NUMBER_INPUT($event,element, parseInt(this.numberLength) )
      }
    }
  },
  mounted() {
    if ( this.avalonStyle ) {
      this.solo = true
      this.flat = true

      setTimeout(()=> {
        var slots = document.querySelectorAll('.avalon .v-input__slot');

        slots.forEach((slot) => {
          slot.style.padding = '0';
        });
      }, 10)

    }
    this.text = this.value
  }
}
</script>

<style lang="css" scoped>
/*.v-text-field--outlined >>> fieldset {*/
/*  border-color: rgba(192, 0, 250, 0.986);*/
/*}*/


.notActive.v-text-field--outlined >>> fieldset {
  border-color: #9AB2C4 !important;
}
.activeText.v-text-field--outlined >>> fieldset {
  border-color: #567186 !important;
  border-width: 2px;
}
.notActive.v-text-field--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.notActive.v-text-field--solo-flat >>> input::placeholder {
  color: #9AB2C4 !important;
}
.notActive.v-textarea--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.notActive.v-textarea--solo >>> input::placeholder {
  color: #9AB2C4 !important;
}
.notActive.v-textarea--solo-flat >>> input::placeholder {
  color: #9AB2C4 !important;
}
.errorStyle.v-text-field--outlined >>> input::placeholder {
  border-color: darkred !important;
  border-width: 2px;
}

.textStyle {
  font-weight: 500;
  font-size: 16px;
}



</style>